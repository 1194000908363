import "../../scss/views/forms.scss";
import Main from "../Main";
import useToken from "../utils/Token";
import { LoginReq } from "../utils/login/requests";
import { ModeContext } from "../utils/Mode";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Redirect } from "react-router";
import { Helmet } from "react-helmet";

export default function Login(props) {
  const { t } = useTranslation();
  const location = "/login";
  const { token } = useToken();
  if (token) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: {
            from: location,
          },
        }}
      />
    );
  } else {
    return (
      <Fragment>
        <Helmet>
          <title>{t("metadata.login.title")}</title>
          <meta name="description" content={t("metadata.login.description")}/>
        </Helmet>
        <Main
          location={location}
          card2={<Page location={location} setToken={props.setToken}/>}
        />
      </Fragment>
    );
  }
}

// Login Form with Card-Design
function Page(props) {
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [requestInterface, setRequestInterface] = useState(<ReqButton />);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (login) {
      if (password) {
        setRequestInterface(
          <LoginReq
            login={login}
            password={password}
            setToken={props.setToken}
            location={props.location}
          />
        );
      } else {
        // no password
      }
    } else {
      // no login
    }
  };

  const changeLogin = (e) => {
    if (e !== login) {
      setLogin(e);
    }
  };

  const changePassword = (e) => {
    if (e !== password) {
      setPassword(e);
    }
  };

  return (
    <PageView
      handleSubmit={handleSubmit}
      changeLogin={(e) => changeLogin(e.target.value)}
      changePassword={(e) => changePassword(e.target.value)}
      requestInterface={requestInterface}
    />
  );
}

function ReqButton(props) {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  return (
    <div className={m("btn-wrap")}>
      <div className={m("btn")}>
        <button type="submit">
          <div className={m("btn-text")}>{t("form.login.action")}</div>
        </button>
      </div>
    </div>
  );
}

function PageView(props) {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  const { 
    handleSubmit,
    changeLogin,
    changePassword,
    requestInterface
   } = props;

  return(
    <div className={m("login form-wrap")}>
      <form onSubmit={handleSubmit}>
        <div className={m("form")}>
          <div className={m("forms-title")}>{t("form.login.title")}</div>
          <div className={m("single-signon")}>
            <div className={m("btn-google-wrap")}>
              <div className={m("btn-google")}>
                <img className={m("btn-google-icon")} alt="Google Login"></img>
                <div className={m("btn-google-text")}>
                  {t("form.login.google")}
                </div>
              </div>
            </div>
          </div>
          <div className={m("mask")}>
            <div className={m("input-form-wrap")}>
              <div className={m("input-form")}>
                <div className={m("input-icon")}>
                  <span className={m("material-icons")}>account_circle</span>
                </div>
                <input
                  id="user"
                  className={m("input")}
                  type="text"
                  onChange={changeLogin}
                  placeholder={t("form.login.login")}
                ></input>
              </div>
            </div>
            <div className={m("input-form-wrap")}>
              <div className={m("input-form")}>
                <div className={m("input-icon")}>
                  <span className={m("material-icons")}>lock</span>
                </div>
                <input
                  id="password"
                  className={m("input")}
                  type="password"
                  autoComplete="current-password"
                  onChange={changePassword}
                  placeholder={t("form.login.password")}
                ></input>
              </div>
            </div>
            {requestInterface}
          </div>
        </div>
      </form>
    </div>
  );
}