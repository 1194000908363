import {
  UserIcon,
  EmailIcon,
  ValidIcon,
  NotValidIcon,
  ErrorIcon,
  LoadingIcon,
} from "../../components/registration/components";
import { url } from "../Config";
import { Get } from "react-axios";

// username icon during and after request
export function UserReq(props) {
  return (
    <Get
      url={url.concat("register/duplicateCheck")}
      params={{
        field: "username",
        value: props.username,
      }}
    >
      {(error, response, isLoading, makeRequest, axios) => {
        if (error) {
          return <ErrorIcon />;
        } else if (isLoading) {
          return <LoadingIcon />;
        } else if (response !== null) {
          if (!response.data) {
            // icon on respone false (no duplicate found)
            props.usernameAvailable.current = true;
            props.setUsernameHint("form.registration.username.available");
            return <ValidIcon />;
          } else {
            // icon on respone true (duplicate found)
            props.usernameAvailable.current = false;
            props.setUsernameHint("form.registration.username.notAvailable");
            return <NotValidIcon />;
          }
        }
        return (
          // default icon before request
          <UserIcon />
        );
      }}
    </Get>
  );
}

// email icon during and after request
export function EmailReq(props) {
  return (
    <Get
      url={url.concat("register/duplicateCheck")}
      params={{
        field: "eMail",
        value: props.email,
      }}
    >
      {(error, response, isLoading, makeRequest, axios) => {
        if (error) {
          return <ErrorIcon />;
        } else if (isLoading) {
          return <LoadingIcon />;
        } else if (response !== null) {
          // console.log("[Registration] EmailReq Response: ", response);
          if (!response.data) {
            // icon on respone false (no duplicate found)
            props.emailAvailable.current = true;
            props.setEmailHint("form.registration.email.available");
            return <ValidIcon />;
          } else {
            // icon on respone true (duplicate found)
            props.emailAvailable.current = false;
            props.setEmailHint("form.registration.email.notAvailable");
            return <NotValidIcon />;
          }
        }
        return (
          // default icon before request
          <EmailIcon />
        );
      }}
    </Get>
  );
}
