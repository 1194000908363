import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ModeContext } from "../../utils/Mode";

// username icon
export function UserIcon() {
  const { m } = useContext(ModeContext);
  return (
    <div className={m("input-icon")}>
      <span className={m("material-icons")}>account_circle</span>
    </div>
  );
}

// email icon
export function EmailIcon() {
  const { m } = useContext(ModeContext);
  return (
    <div className={m("input-icon")}>
      <span className={m("material-icons")}>email</span>
    </div>
  );
}

// password icon
export function PassIcon() {
  const { m } = useContext(ModeContext);
  return (
    <div className={m("input-icon")}>
      <span className={m("material-icons")}>lock</span>
    </div>
  );
}

// valid icon
export function ValidIcon() {
  const { m } = useContext(ModeContext);
  return (
    <div className={m("input-icon")}>
      <span className={m("material-icons")}>check</span>
    </div>
  );
}

// not valid icon
export function NotValidIcon() {
  const { m } = useContext(ModeContext);
  return (
    <div className={m("input-icon not-valid-icon")}>
      <span className={m("material-icons")}>close</span>
    </div>
  );
}

// error icon (response)
export function ErrorIcon() {
  const { m } = useContext(ModeContext);
  return (
    <div className={m("input-icon response")}>
      <span className={m("material-icons")}>sync_problem</span>
    </div>
  );
}

// loading icon => spinner (response)
export function LoadingIcon() {
  const { m } = useContext(ModeContext);
  return (
    <div className={m("input-icon response")}>
      <span className={m("material-icons loading")}>sync</span>
    </div>
  );
}

// input hint
export function InputHint(props) {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  return (
    <div className={m("input-form-hint-wrap")}>
      <div style={props.style} className={m("input-form-hint")}>
        {t(props.text)}
      </div>
    </div>
  );
}

// loading
export function RegLoading() {
  const { m } = useContext(ModeContext);
  return (
    <div className={m("btn-wrap")}>
      <div className={m("btn response")}>
        <div className={m("loader")}></div>
      </div>
    </div>
  );
}

// registration success screen
export function RegSuccess() {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  return (
    <div className={m("registration form-wrap")}>
      <div className={m("confirmation-message-wrap")}>
        <div className={m("confirmation-message")}>
          <div className={m("confirmation-message-title")}>
            {t("form.registration.status.success.title")}
          </div>
          <div className={m("confirmation-message-text")}>
            {t("form.registration.status.success.message")}
          </div>
        </div>
      </div>
    </div>
  );
}

// registration failed message
export function RegFailed() {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  return (
    <div className={m("btn-wrap")}>
      <div className={m("message-wrap")}>
        <div className={m("message-form")}>
          <div className={m("message-icon")}>
            <span className={m("material-icons")}>error</span>
          </div>
          <div className={m("message-text")}>
            {t("form.registration.alert.failed")}
          </div>
        </div>
      </div>
      <div className={m("btn response")}>
        <button type="submit">
          <div className={m("btn-text")}>{t("form.general.retry")}</div>
        </button>
      </div>
    </div>
  );
}
