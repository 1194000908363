const initialState = Object.freeze({
  name: "",
  about: "",
  picture: "",
  country: "",
  city: "",
  language: "",
  links: {},
  events: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_NAME": {
      return {
        user: {
          session: action.payload.session,
        },
      };
    }
    case "LOGOUT": {
      return {
        user: state.user,
      };
    }
    default: {
      return state;
    }
  }
};