import "../../scss/components/headpage.scss";
import "../../scss/components/dashboard.scss";
import Sidemenu from "./Sidemenu";
import ExHeader from "./ExHeader";
import FunctionPanel from "./ExFunctionPanel";
import { hpp } from "../utils/Config";
import { ModeContext } from "../utils/Mode";
import { useContext } from "react";

// Headpage containing splitted 2-Card-Design
export default function Dashboard(props) {
  const { m } = useContext(ModeContext);

  const animateTransition = () => {
    if (
      hpp.includes(props.lastLocation) &&
      props.location !== props.lastLocation
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div id={props.id} className={m("container")}>
      <Sidemenu location={props.location} lastLocation={props.lastLocation} />
      <ExHeader location={props.location} lastLocation={props.lastLocation} />
      {/* 
            CSS Var to set an animation in ::before and wrap node
            Use last location to decide between different animations
            */}
      <div
        className={m("head-page dashboard-page")}
        style={
          (props.lastLocation === "") | null | undefined
            ? { "--db-animation": "fade-in 1s forwards" }
            : null
        }
      >
        <div className={m("headpage-content-wrap")}>
          <div className={m("header-space")}></div>
          <div className={m("headpage-interface-wrap")}>
            <div className={m("headpage-interface")}>
              <div
                className={m("head-card-wrap dashboard-card-wrap")}
                style={
                  animateTransition()
                    ? {
                        "--dbcw-animation":
                          "scale-hp-to-db-cardwrap 1s forwards",
                        "--dbcw-animation-tablet":
                          "scale-hp-to-db-cardwrap-tablet 1s forwards",
                        "--dbcw-animation-mobile":
                          "scale-hp-to-db-cardwrap-mobile 1s forwards",
                      }
                    : (props.lastLocation === "") | null | undefined
                    ? {
                        "--dbcw-animation": "fade-in-up 1s forwards",
                        "--dbcw-animation-tablet": "fade-in-up 1s forwards",
                        "--dbcw-animation-mobile": "fade-in-up 1s forwards",
                      }
                    : null
                }
              >
                <div
                  id="card1"
                  style={
                    animateTransition()
                      ? {
                          "--dbc1-animation":
                            "scale-hp-to-db-card1 1s forwards",
                          "--dbc1-animation-tablet":
                            "scale-hp-to-db-card1-tablet 1s forwards",
                          "--dbc1-animation-mobile":
                            "scale-hp-to-db-card1-mobile 1s forwards",
                        }
                      : null
                  }
                  className={m("head-card head-card1 dashboard-card1")}
                >
                  {props.card1 == null ? <FunctionPanel /> : props.card1}
                </div>
                <div
                  id="card2"
                  style={
                    animateTransition()
                      ? {
                          "--dbc2-animation":
                            "scale-hp-to-db-card2 1s forwards",
                          "--dbc2-animation-tablet":
                            "scale-hp-to-db-card2-tablet 1s forwards",
                          "--dbc2-animation-mobile":
                            "scale-hp-to-db-card2-mobile 1s forwards",
                        }
                      : null
                  }
                  className={m("head-card head-card2 dashboard-card2")}
                >
                  <div className={m("head-card2-content")}>{props.card2}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={m("headpage-footer-space dashboard-footer-space")}
            style={
              animateTransition()
                ? {
                    "--db-fs-animation": "scale-hp-to-db-fs 1s forwards",
                    "--db-fs-animation-tablet":
                      "scale-hp-to-db-fs-tablet 1s forwards",
                    "--db-fs-animation-mobile":
                      "scale-hp-to-db-fs-mobile 1s forwards",
                  }
                : null
            }
          ></div>
        </div>
      </div>
    </div>
  );
}
