import "../../../../scss/components/profile/events/profileevent.scss";
import { month } from "../../../utils/profile/utils";
import { useContext } from "react";
import { ModeContext } from "../../../utils/Mode";

export function ProfileEvent(props) {
  const data = props.data;

  const getDay = () => {
    return props.data.date.split("-")[0];
  };

  const getMonth = () => {
    return month[props.data.date.split("-")[1] - 1];
  };

  return (
    <ProfileEventView
      title={data.title}
      subtitle={data.subtitle}
      getDay={getDay()}
      getMonth={getMonth()}      
    />
  );
}

function ProfileEventView(props){
  const { m } = useContext(ModeContext);
  const { title, subtitle, getDay, getMonth } = props;

  return (
    <div className={m("profile-event")}>
      <div className={m("profile-event-icon-wrap")}>
        <div className={m("profile-event-icon")}>
          <div className={m("profile-event-icon-img")}>
            <span className={m("material-icons")}>calendar_today</span>
            <div className={m("profile-event-icon-img-caption")}>
              {getDay}
            </div>
          </div>
          <div className={m("profile-event-icon-caption-month")}>
            {getMonth}
          </div>
        </div>
      </div>
      <div className={m("profile-event-content-wrap")}>
        <div className={m("profile-event-content")}>
          <div className={m("profile-event-content-title")}>{title}</div>
          <div className={m("profile-event-content-title-adds")}>
            {subtitle}
          </div>
        </div>
      </div>
    </div>
  );
}