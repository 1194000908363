import "../../scss/views/events.scss";
import Main from "../Main";
import { ModeContext } from "../utils/Mode";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Events(props) {
  return <Main location="/events" card2={<Page />} />;
}

function Page() {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  return (
    <Fragment>
      <Helmet>
        <title>{t("metadata.events.title")}</title>
        <meta name="description" content={t("metadata.events.description")}/>
      </Helmet>
      <div className={m("placeholder")}>
        {t("functions.placeholder.description")}
      </div>
    </Fragment>
  );
}
