import "../../scss/components/headpage.scss";
import { dbp } from "../utils/Config";
import { ModeContext } from "../utils/Mode";
import { useContext } from "react";
import Sidemenu from "./Sidemenu";
import Header from "./Header";
import FunctionPanel from "./FunctionPanel";

// Headpage containing splitted 2-Card-Design
export default function HeadPage(props) {
  const { m } = useContext(ModeContext);

  const animateTransition = () => {
    if (
      dbp.includes(props.lastLocation) &&
      props.location !== props.lastLocation
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div id={props.id} className={m("container")}>
      <Sidemenu location={props.location} lastLocation={props.lastLocation} />
      <Header location={props.location} />
      {/* 
            CSS Var to set an animation in ::before and wrap node
            Use last location to decide between different animations
            */}
      <div
        className={m("head-page")}
        style={
          props.lastLocation === ""
            ? { "--hp-animation": "fade-in 1s forwards" }
            : null
        }
      >
        <div className={m("headpage-content-wrap")}>
          <div className={m("header-space")}></div>
          <div className={m("headpage-interface-wrap")}>
            <div className={m("headpage-interface")}>
              <div
                className={m("head-card-wrap")}
                style={
                  animateTransition()
                    ? {
                        "--hpcw-animation":
                          "scale-db-to-hp-cardwrap 1s forwards",
                        "--hpcw-animation-tablet":
                          "scale-db-to-hp-cardwrap-tablet 1s forwards",
                        "--hpcw-animation-mobile":
                          "scale-db-to-hp-cardwrap-mobile 1s forwards",
                      }
                    : (props.lastLocation === "") | null | undefined
                    ? {
                        "--hpcw-animation": "fade-in-up 1s forwards",
                        "--hpcw-animation-tablet": "fade-in-up 1s forwards",
                        "--hpcw-animation-mobile": "fade-in-up 1s forwards",
                      }
                    : null
                }
              >
                <div
                  className={m("head-card head-card1")}
                  id="card1"
                  style={
                    animateTransition()
                      ? {
                          "--hpc1-animation":
                            "scale-db-to-hp-card1 1s forwards",
                          "--hpc1-animation-tablet":
                            "scale-db-to-hp-card1-tablet 1s forwards",
                          "--hpc1-animation-mobile":
                            "scale-db-to-hp-card1-mobile 1s forwards",
                        }
                      : null
                  }
                >
                  {props.card1 === undefined ? <FunctionPanel /> : props.card1}
                </div>
                <div
                  className={m("head-card head-card2")}
                  id="card2"
                  style={
                    animateTransition()
                      ? {
                          "--hpc2-animation":
                            "scale-db-to-hp-card2 1s forwards",
                          "--hpc2-animation-tablet":
                            "scale-db-to-hp-card2-tablet 1s forwards",
                          "--hpc2-animation-mobile":
                            "scale-db-to-hp-card2-mobile 1s forwards",
                        }
                      : null
                  }
                >
                  <div className={m("head-card2-content")}>{props.card2}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={m("headpage-footer-space")}
            style={
              animateTransition()
                ? {
                    "--hp-fs-animation": "scale-db-to-hp-fs 1s forwards",
                    "--hp-fs-animation-tablet":
                      "scale-db-to-hp-fs-tablet 1s forwards",
                    "--hp-fs-animation-mobile":
                      "scale-db-to-hp-fs-mobile 1s forwards",
                  }
                : null
            }
          ></div>
        </div>
      </div>
    </div>
  );
}
