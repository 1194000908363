import validator from "validator";

// check username
export const validUsername = (username) => {
  if (username.length < 3 || username.length > 20) return false;
  if (!validator.isAlphanumeric(validator.blacklist(username, ".\\-\\_")))
    return false;
  return true;
};

// check email
export const validEmail = (email) => {
  return validator.isEmail(email);
};

// check password
export const validPassword = (password) => {
  return validator.isStrongPassword(password, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  });
};

// check password confirmation
export const validPasswordConfirmation = (password, passwordConfirmation) => {
  return password === passwordConfirmation;
};

// catch error and return i18n-error-code
export const getErrorMessage = (
  username,
  usernameAvailable,
  email,
  emailAvailable,
  password,
  passwordConfirmation
) => {
  var error = null;
  // check filled
  if (
    username === "" ||
    email === "" ||
    password === "" ||
    passwordConfirmation === ""
  ) {
    error = "form.general.empty";
    // check username valid
  } else if (!validUsername(username)) {
    error = "form.registration.error.invalidUsername";
    // check username available
  } else if (!usernameAvailable) {
    error = "form.registration.error.notAvailableUsername";
    // check email valid
  } else if (!validEmail(email)) {
    error = "form.registration.error.invalidEmail";
    // check email available
  } else if (!emailAvailable) {
    error = "form.registration.error.notAvailableEmail";
    // check password valid
  } else if (!validPassword(password)) {
    error = "form.registration.error.invalidPassword";
    // check password = password-confirmation
  } else if (password !== passwordConfirmation) {
    error = "form.registration.error.passwordConfirmation";
  }

  return error;
};
