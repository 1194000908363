import React from "react";
import { ModeContext } from "../utils/Mode";
import { useContext } from "react";
import "../../scss/components/navigation.scss";

// Footer on last Subpage
export default function Footer(props) {
  const { m } = useContext(ModeContext);

  return (
    <div className={m("footer")}>
      <div className={m("menu")}>
        <div
          className={m("menu-item footer-menu-item")}
          onClick={() => {
            alert("Bald ist es so weit!");
          }}
        >
          Impressum
        </div>
      </div>
    </div>
  );
}
