import { url } from "../Config";
import { ErrorMessage } from "../../components/login/components";
import { Post } from "react-axios";
import { useContext } from "react";
import { ModeContext } from "../Mode";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

export function LoginReq(props) {
    const { m } = useContext(ModeContext);
    const { t } = useTranslation();
    return (
      <Post
        url={url.concat("login")}
        data={{
          login: props.login,
          password: props.password,
        }}
      >
        {(error, response, isLoading, makeRequest, axios) => {
          if (error) {
            // error message
            return <ErrorMessage code={error.response.status} />;
          } else if (isLoading) {
            return (
              <div className={m("btn-wrap")}>
                <div className={m("btn response")}>
                  <div className={m("loader")}></div>
                </div>
              </div>
            );
          } else if (response !== null) {
            // if login successful
            if (response.status === 200) {
              props.setToken(response.data.session);
              return (
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            } else {
              // data false
              return (
                <div className={m("btn-wrap")}>
                  <div className={m("message-wrap")}>
                    <div className={m("message-form")}>
                      <div className={m("message-icon")}>
                        <span className={m("material-icons")}>error</span>
                      </div>
                      <div className={m("message-text")}>
                        {t("form.login.alert.failed")}
                      </div>
                    </div>
                  </div>
                  <div className={m("btn btn-lower-margin response")}>
                    <button type="submit">
                      <div className={m("btn-text")}>
                        {t("form.general.retry")}
                      </div>
                    </button>
                  </div>
                </div>
              );
            }
          }
          // default message before request is made
          return (
            <div className={m("btn-wrap")}>
              <div className={m("btn response")}>
                <div className={m("loader")}></div>
              </div>
            </div>
          );
        }}
      </Post>
    );
  }