import "../scss/main.scss";
import Footer from "./components/Footer";
import HeadPage from "./components/HeadPage";
import SubPage from "./components/Subpage";
import Info1 from "./components/Info1";
import Info2 from "./components/Info2";
import About from "./components/About";
import { ModeContext } from "./utils/Mode";
import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

export default function Main(props) {
  const { m } = useContext(ModeContext);

  let history = useHistory();
  if (history.location.state === undefined) {
    history.location.state = { from: "" };
  }

  return (
    <div className={m("content")}>
      {/* {history.location.state !== undefined ? console.log("[Main] Coming from: ", history.location.state.from) : null} */}
      <HeadPage
        id={"head"}
        location={props.location}
        lastLocation={history.location.state.from}
        card1={props.card1}
        card2={props.card2}
      />
      <SubPage title={1} id={"info1"} content={<Info1 />} />
      <SubPage title={2} id={"info2"} content={<Info2 />} />
      <SubPage title={1} id={"about"} content={<About />} adds={<Footer />} />
    </div>
  );
}
