import "../../scss/components/navigation.scss";
import Header from "./Header";
import { ModeContext } from "../utils/Mode";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useToken from "../utils/Token";

// Header Bar Navigation
export default function ExHeader(props) {
  // userToken saved in localStorage to save session
  const { removeToken } = useToken();
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();

  const exHeaderItems = [
    // header item 1
    <Link
      to={{
        pathname: "/",
        state: {
          from: props.location,
        },
      }}
    >
      <div className={m("menu-item")}>{t("menu.landing")}</div>
    </Link>,
    // header item 2
    <Link
      to={{
        pathname: "/",
        state: {
          from: props.location,
        },
      }}
    >
      <button onClick={() => removeToken()}>
        <div className={m("menu-item")}>{t("menu.logout")}</div>
      </button>
    </Link>
  ]

  return <Header location={props.location} userItems={exHeaderItems}/>;
}