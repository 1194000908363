import "../../../../scss/components/profile/friends/profilefriend.scss";
import examplePic from "../../../../img/example-user-picture.jpg";
import { useContext } from "react";
import { ModeContext } from "../../../utils/Mode";

export function ProfileFriend(props) {
  const { data } = props;

  const Preview = () => {
    return (
      <ProfileFriendPreview
        name={data.name}
        location={data.location.concat(", ").concat(data.country)}
      />
    );
  };

  return <ProfileFriendView Preview={Preview} />;
}

function ProfileFriendView(props) {
  const { m } = useContext(ModeContext);
  const { Preview } = props;

  return (
    <div className={m("profile-friend-wrap")}>
      <div className={m("profile-friend")}>
        <img src={examplePic} alt="example"></img>
      </div>
      <Preview />
    </div>
  );
}

function ProfileFriendPreview(props) {
  const { m } = useContext(ModeContext);
  const { name, location } = props;

  return (
    <div className={m("friend-preview")}>
      <div className={m("friend-preview-content")}>
        <div className={m("friend-preview-details")}>
          <div className={m("friend-preview-name")}>{name}</div>
          <div className={m("friend-preview-location")}>
            <div className={m("friend-preview-location-icon")}>
              <span className={m("material-icons")}>place</span>
            </div>
            <div className={m("friend-preview-location-text")}>{location}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
