import "../../scss/components/sidemenu.scss";
import LanguageSwitcher from "./LanguageSwitcher";
import { ModeSwitch, ModeContext } from "../utils/Mode";
import { useContext } from "react";
import { Link } from "react-router-dom";

export default function Sidemenu(props) {
  const { m } = useContext(ModeContext);

  return (
    <div className={m("side-menu-wrap")}>
      <div className={m("side-menu")}>
        <Link
          to={{
            pathname: "/",
            state: {
              from: props.location,
            },
          }}
        >
          <div className={m("side-menu-image")}>
            <img src="/logo192.png" alt="Logo"></img>
          </div>
        </Link>
        <div className={m("side-menu-element side-lang")}>
          <LanguageSwitcher />
        </div>
        <ModeSwitch />
      </div>
    </div>
  );
}
