import "../../scss/components/languageswitcher.scss";
import { ModeContext } from "../utils/Mode";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CircleFlag } from "react-circle-flags";

// Language Switcher (Flag Icon)
export default function LanguageSwitcher(props) {
  const { m } = useContext(ModeContext);
  const { i18n } = useTranslation();
  const lngs = {
    en: {
      nativeName: "English",
      icon: <CircleFlag countryCode="gb" height="50" />,
    },
    de: {
      nativeName: "Deutsch",
      icon: <CircleFlag countryCode="de" height="50" />,
    },
  };
  return (
    <div
      className={m("language-switcher" + " " + props.addClass)}
      onClick={() => {
        i18n.language === "en"
          ? i18n.changeLanguage("de")
          : i18n.changeLanguage("en");
      }}
    >
      {i18n.language === "en" ? lngs["en"].icon : lngs["de"].icon}
    </div>
  );
}
