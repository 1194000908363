import "../../../scss/components/slideshow/sliders.scss";
import { ModeContext } from "../../utils/Mode";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

// Array of all Slides for Slideshow at Landing Page
export default function sliders() {
  return [<One />, <Two />, <Three />, <Four />];
}

function One() {
  const { m } = useContext(ModeContext);
  // const { t } = useTranslation();
  return (
    <div className={m("slide1 slider-wrap")}>
      <div className={m("slider-design")}>
        <img src="/logo512.png" alt="Logo"></img>
        <div className={m("slider-text")}>{/* {t("sliders.text1")} */}</div>
      </div>
    </div>
  );
}

function Two() {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  return (
    <div className={m("slide2 slider-wrap")}>
      <div className={m("slider-design")}>
        <div className={m("slider-text")}>{t("sliders.text2")}</div>
      </div>
    </div>
  );
}

function Three() {
  const { m } = useContext(ModeContext);
  // const { t } = useTranslation();
  return (
    <div className={m("slide3 slider-wrap")}>
      <div className={m("slider-design")}>
        <div className={m("slider-text")}>{/* {t("sliders.text3")} */}</div>
      </div>
    </div>
  );
}

function Four() {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  return (
    <div className={m("slide4 slider-wrap")}>
      <div className={m("slider-design")}>
        <div className={m("slider-text")}>{t("sliders.text4")}</div>
      </div>
    </div>
  );
}
