import { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import validator from "validator";

const modes = ["light", "dark"];
const modesThemeColors = ["#f69e7b", "#121212"];
const modeIcons = { light: "light_mode", dark: "dark_mode" };

export function ModeProvider(props) {
  const [mode, setMode] = useState(getMode());
  // FLAG
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    selectCSS();
  }, [mode, flag]);

  const selectCSS = () => {
    // FLAG
    if (flag) {
      try {
        import("../../scss/utils/modes/" + mode + ".scss");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const modeIcon = () => {
    return modeIcons[mode];
  };

  const saveMode = (userMode) => {
    if (!userMode || !validator.isIn(userMode, modes)) userMode = modes[0];
    // save mode to local storage
    localStorage.setItem("mode", JSON.stringify(userMode));
    setMode(userMode);
    // trigger re-rendering
  };

  const nextMode = () => {
    return modes[(modes.indexOf(mode) + 1) % modes.length];
  };

  const nextIcon = () => {
    return modeIcons[nextMode()];
  };

  const setNextMode = () => {
    saveMode(nextMode());
  };

  const getThemeColor = () => {
    return modesThemeColors[modes.findIndex((i) => i === mode)];
  }

  const transformClass = (classNames) => {
    var trans = "";
    classNames.split(" ").forEach((element) => {
      trans === "" ? (trans = element) : (trans += " " + element);
      trans += " " + element + "-" + mode;
    });
    return trans;
  };

  return (
    <ModeContext.Provider
      value={{
        mode,
        modeIcon,
        setMode: saveMode,
        nextMode,
        nextIcon,
        setNextMode,
        getThemeColor,

        // FLAG
        flag,
        setFlag,

        m: transformClass,
      }}
    >
      {props.children}
    </ModeContext.Provider>
  );
}

const getMode = () => {
  const modeString = localStorage.getItem("mode");
  if (modeString) {
    const userMode = JSON.parse(modeString);
    if (userMode !== undefined || userMode !== null) {
      if (validator.isIn(userMode, modes)) {
        return userMode;
      }
    }
  }
  return modes[0];
};

// keep only one instance with context prevents reading from local storage >10 times/view (getMode)
export const ModeContext = createContext(getMode());

export function ModeSwitch() {
  // FLAG
  const { flag, setNextMode, nextIcon, m } = useContext(ModeContext);
  if (flag) {
    return (
      <div className={m("side-menu-element")}>
        <div className={m("side-menu-icon")} onClick={() => setNextMode()}>
          <span className={m("material-icons")}>{nextIcon()}</span>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
