import "../../scss/components/fallback.scss";
import { ModeContext } from "../utils/Mode";
import { useContext } from "react";

export default function Fallback() {
  const { m } = useContext(ModeContext);

  return (
    <div className={m("fallback")}>
      <div className={m("fallback-content")}>
        <div className={m("fallback-loader")}></div>
        <img className={m("fallback-img")} src="/logo512.png" alt="Logo"></img>
      </div>
    </div>
  );
}
