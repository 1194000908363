import React from "react";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({ component: Component, auth, redirect, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        // TODO: implement authentification
        if (auth) {
          return <Component {...rest} {...props} />;
        } else {
          if (redirect) {
            // redirect on param
            return (
              <Redirect
                to={{
                  pathname: redirect,
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            // no redirect-param
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        }
      }}
    />
  );
};

export default ProtectedRoute;
