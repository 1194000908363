export const getErrorMessage = (code) => {
  var error = "form.general.error";

  switch (code) {
    case 400:
      error = "form.login.errorcodes.400";
      break;
    case 403:
      error = "form.login.errorcodes.403";
      break;
    case 404:
      error = "form.login.errorcodes.404";
      break;
    case 500:
      error = "form.login.errorcodes.500";
      break;
    default:
      error = "form.general.error";
  }

  return error;
};
