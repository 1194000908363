import "../../scss/components/info.scss";
import { ModeContext } from "../utils/Mode";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

// Subpage 2 for Informations
export default function Info2(props) {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  return (
    <div className={m("subpage")}>
      <div className={m("subpage-content")}>
        <div className={m("title")}>{t("info.info2.title")}</div>
        <div className={m("description")}>{t("info.info2.content")}</div>
      </div>
    </div>
  );
}
