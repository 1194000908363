import "../../scss/components/navigation.scss";
import LanguageSwitcher from "./LanguageSwitcher";
import { ModeContext } from "../utils/Mode";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useToken from "../utils/Token";

// Header Bar Navigation
export default function Header(props) {
  // userToken saved in localStorage to save session
  const { token, removeToken } = useToken();
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  const { userItems } = props;

  const newUserItems = [
    // item 1
      <HeaderLink
      path="/login"
      location={props.location}
      name="menu.login"
    />,
    // item 2
    <HeaderLink
      path="/registration"
      location={props.location}
      name="menu.registration"
    />,
    // item 3
    <a href="#about">
      <div className={m("menu-item")}>{t("menu.info")}</div>
    </a>
  ];

  const defaultUserItems = [
    // header item 1 - link dashboard
    <Link
      to={{
        pathname: "/dashboard",
        state: {
          from: props.location,
        },
      }}
    >
      <div className={m("menu-item")}>{t("menu.dashboard")}</div>
    </Link>,
    // header item 2 - logout
    <Link
      to={{
        pathname: "/",
        state: {
          from: props.location,
        },
      }}
    >
      <button onClick={() => removeToken()}>
        <div className={m("menu-item")}>{t("menu.logout")}</div>
      </button>
    </Link>,
    // header item 3 - link info
    <a href="#about">
      <div className={m("menu-item")}>{t("menu.info")}</div>
    </a>
  ]

  const listHeaderItems = (items) => {
    return items.map((object, index) => (
      object
    ));
  }

  if (token) {
    return (
      <HeaderView
        HeaderItems={listHeaderItems((userItems) ? userItems : defaultUserItems)}
      />
    );
  } else {
    return (
      <HeaderView
        HeaderItems={listHeaderItems(newUserItems)}
      />
    );
  }
}

function HeaderView(props) {
  const { m } = useContext(ModeContext);
  const { HeaderItems } = props;

  return(
    <div className={m("header-wrap")}>
      <div className={m("header")}>
        <div className={m("menu-wrap")}>
          <div className={m("menu")}>
            <LanguageSwitcher addClass="mobile-hidden" />
            {HeaderItems}
          </div>
        </div>
      </div>
    </div>
  );
}

function HeaderLink(props){
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  const { path, location, name } = props;

  return (
    <Link
      to={{
        pathname: path,
        state: {
          from: location,
        },
      }}
    >
      <div className={m("menu-item")}>{t(name)}</div>
    </Link>
  );
}