import "../../scss/views/events.scss";
import ExMain from "../ExMain";
import { ModeContext } from "../utils/Mode";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function Calendar(props) {
  return <ExMain location="/calendar" card2={<Page />} />;
}

function Page() {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  return (
    <div className={m("placeholder")}>
      {t("functions.placeholder.description")}
    </div>
  );
}
