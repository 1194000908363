import "../../scss/views/profilesettings.scss";
import ExMain from "../ExMain";
import { colors } from "../utils/Config";
import { ModeContext } from "../utils/Mode";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ProfileSettings(props) {
  return (
    <ExMain
      location="/profile/settings"
      card2={<Page location="/profile/settings" />}
    />
  );
}

function Page(props) {
  const { m } = useContext(ModeContext);

  const [activeSetting, setActiveSetting] = useState(<Contents />);

  return (
    <div className={m("profile-settings")}>
      <Link
        to={{
          pathname: "/profile",
          state: {
            from: props.location,
          },
        }}
      >
        <div className={m("back-icon")}>
          <span className={m("material-icons")}>keyboard_backspace</span>
        </div>
      </Link>

      <div className={m("profile-settings-menu-wrap")}>
        <div className={m("profile-settings-menu")}>
          <Setting
            icon="manage_accounts"
            title="functions.profile.settings.types.content"
            component={<Contents />}
            active={activeSetting}
            action={(e) => setActiveSetting(e)}
          />
          <Setting
            icon="date_range"
            title="functions.profile.settings.types.events"
            component={<Events />}
            active={activeSetting}
            action={(e) => setActiveSetting(e)}
          />
          <Setting
            icon="admin_panel_settings"
            title="functions.profile.settings.types.privacy"
            component={<Privacy />}
            active={activeSetting}
            action={(e) => setActiveSetting(e)}
          />
        </div>
      </div>

      <div className={m("profile-settings-details-wrap")}>
        <div className={m("profile-settings-details")}>{activeSetting}</div>
      </div>
    </div>
  );
}

function Setting(props) {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();

  // console.log("props.active:", props.active, "props.component:", props.component);

  const active = () => {
    return props.active.type.name === props.component.type.name;
  };

  return (
    <div className={m("profile-settings-menu-entry-wrap")}>
      <div
        className={m("profile-settings-menu-entry")}
        onClick={() => props.action(props.component)}
      >
        <div className={m("profile-settings-menu-entry-icon")}>
          <span
            className={m("material-icons")}
            style={active() ? { color: colors.primCol } : null}
          >
            {props.icon}
          </span>
        </div>
        <div
          className={m("profile-settings-menu-entry-title")}
          style={
            active() ? { fontWeight: "bold", color: colors.primCol } : null
          }
        >
          {t(props.title)}
        </div>
      </div>
    </div>
  );
}

// function SettingsContent(props) {
//   const { m } = useContext(ModeContext);
//   const { t } = useTranslation();
//   return(
//     <div className={m("profile-settings-page")}>
//       <div className={m("profile-settings-page-title")}>
//         {t("functions.profile.settings.types.content")}
//       </div>
//       <div className={m("profile-settings-page-content")}>
//         <div className={m("profile-setting-label")}>
//           {t("functions.profile.about.title")}
//         </div>
//         <textarea
//           className={m("profile-setting-input")}
//           rows="4"
//           maxLength="300"
//           placeholder={t("functions.profile.about.description")}
//         ></textarea>
//       </div>
//     </div>
//   );
// }

function Contents(props) {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    // do sth on save
  };

  return (
    <div className={m("profile-settings-page")}>
      <div className={m("profile-settings-page-title")}>
        {t("functions.profile.settings.types.content")}
      </div>
      <div className={m("profile-settings-page-content")}>
        <div className={m("profile-setting-label")}>
          {t("functions.profile.about.title")}
        </div>
        <textarea
          className={m("profile-setting-input")}
          rows="4"
          maxLength="300"
          placeholder={t("functions.profile.about.description")}
        ></textarea>
      </div>
    </div>
  );
}

function Events(props) {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();

  return (
    <div className={m("profile-settings-page")}>
      <div className={m("profile-settings-page-title")}>
        {t("functions.profile.settings.types.events")}
      </div>
      <div className={m("profile-settings-page-content")}>
        {t("functions.placeholder.description")}
      </div>
    </div>
  );
}

function Privacy(props) {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();

  return (
    <div className={m("profile-settings-page")}>
      <div className={m("profile-settings-page-title")}>
        {t("functions.profile.settings.types.privacy")}
      </div>
      <div className={m("profile-settings-page-content")}>
        {t("functions.placeholder.description")}
      </div>
    </div>
  );
}
