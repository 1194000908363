export const profileData = {
  profile: {
    name: "Max Mustermann",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna " +
      "aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata " +
      "sanctus est Lorem ipsum dolor sit amet. Lore",
    country: "Deutschland",
    city: "Dresden",
  },
  friends: {
    id1: {
      name: "Rico Adler",
      location: "Hamburg",
      country: "Deutschland",
      pid: "1",
    },
    id2: {
      name: "Max Mustermann",
      location: "Dresden",
      country: "Deutschland",
      pid: "2",
    },
    id3: {
      name: "Josi Steinert",
      location: "Dresden",
      country: "Deutschland",
      pid: "3",
    },
    id4: {
      name: "Peter Merseburger",
      location: "Hamburg",
      country: "Deutschland",
      pid: "4",
    },
    id5: {
      name: "Abbigail Schantal",
      location: "Hamburg",
      country: "Deutschland",
      pid: "5",
    },
    id6: {
      name: "Judith Kete",
      location: "Ahaus",
      country: "Deutschland",
      pid: "6",
    },
  },
  events: {
    id1: {
      date: "01-01-2021",
      title: "Musterevent 1",
      subtitle: "Musterevent-Untertitel",
    },
    id2: {
      date: "10-06-1996",
      title: "Musterevent 2",
      subtitle: "Musterevent-Untertitel",
    },
    id3: {
      date: "03-12-1995",
      title: "Musterevent 3",
      subtitle: "Musterevent-Untertitel",
    },
    id4: {
      date: "31-12-2021",
      title: "Musterevent 4",
      subtitle: "Musterevent-Untertitel",
    },
    id5: {
      date: "31-12-2021",
      title: "Musterevent 5",
      subtitle: "Musterevent-Untertitel",
    },
    id6: {
      date: "31-12-2021",
      title: "Musterevent 6",
      subtitle: "Musterevent-Untertitel",
    },
    id7: {
      date: "31-12-2021",
      title: "Musterevent 7",
      subtitle: "Musterevent-Untertitel",
    },
  },
};
