import "../../scss/components/subpage.scss";
import { ModeContext } from "../utils/Mode";
import { useContext } from "react";

export default function SubPage(props) {
  const { m } = useContext(ModeContext);

  return (
    <div id={props.id} className={m("page")}>
      <div className={m("header-space")}></div>
      <Card props={props} />
      <div className={m("headpage-footer-space")}>{props.adds}</div>
    </div>
  );
}

// Wrap of Info Subpages with Card-Design
function Card(props) {
  const { m } = useContext(ModeContext);

  return <div className={m("card")}>{props.props.content}</div>;
}
