import "../../scss/views/forms.scss";
import "../../scss/utils/buttons.scss";
import Main from "../Main";
import useToken from "../utils/Token";
import { url } from "../utils/Config";
import {
  validUsername,
  validEmail,
  validPassword,
  validPasswordConfirmation,
  getErrorMessage,
} from "../utils/registration/utils";
import { UserReq, EmailReq } from "../utils/registration/requests";
import {
  UserIcon,
  EmailIcon,
  PassIcon,
  ValidIcon,
  NotValidIcon,
  InputHint,
  RegSuccess,
  RegFailed,
  RegLoading,
} from "../components/registration/components";
import { ModeContext } from "../utils/Mode";
import { Post } from "react-axios";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router";
import { Helmet } from "react-helmet";

export default function Registration(props) {
  const { t } = useTranslation();
  const location = "/registration";
  const { token } = useToken();

  if (token) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: {
            from: location,
          },
        }}
      />
    );
  } else {
    return (
      <Fragment>
        <Helmet>
          <title>{t("metadata.registration.title")}</title>
          <meta
            name="description"
            content={t("metadata.registration.description")}
          />
        </Helmet>
        <Main location={location} card2={<Reg />} />
      </Fragment>
    );
  }
}

// Registration form with Card-Design
function Reg(props) {
  const [regStatus, setRegStatus] = useState(0);
  const [data, setData] = useState("");

  // status 0 = initial state
  if (regStatus === 0) {
    return (
      <RegForm
        setRegStatus={setRegStatus}
        setData={setData}
        reqButton={<ReqButton />}
      />
    );
    // status 1 = start request
  } else if (regStatus === 1) {
    return <RegReq data={data} />;
    // status 2 = success (after request)
  } else if (regStatus === 2) {
    return <RegSuccess />;
    // status 3 = error
  } else if (regStatus === 3) {
    return (
      <RegForm
        setRegStatus={setRegStatus}
        setData={setData}
        reqButton={<ReqButton error={data.error} />}
      />
    );
    // status 4 = fallback
  } else
    return (
      <RegForm
        setRegStatus={setRegStatus}
        setData={setData}
        reqButton={<ReqButton />}
      />
    );
}

function RegForm(props) {
  const [username, setUsername] = useState("");
  const usernameAvailable = useRef(false);
  const [usernameInterface, setUsernameInterface] = useState(<UserIcon />);
  const [usernameHint, setUsernameHint] = useState(
    "form.registration.username.description"
  );
  const [email, setEmail] = useState("");
  const emailAvailable = useRef(false);
  const [emailInterface, setEmailInterface] = useState(<EmailIcon />);
  const [emailHint, setEmailHint] = useState(
    "form.registration.email.description"
  );
  const [password, setPassword] = useState("");
  const [passwordInterface, setPasswordInterface] = useState(<PassIcon />);
  const [passwordHint, setPasswordHint] = useState(
    "form.registration.password.description"
  );
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordConInterface, setPasswordConInterface] = useState(
    <PassIcon />
  );
  const [passwordConHint, setPasswordConHint] = useState(
    "form.registration.password.description"
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    // checkData(username, email, password, passwordConfirmation);
    const error = getErrorMessage(
      username,
      usernameAvailable.current,
      email,
      emailAvailable.current,
      password,
      passwordConfirmation
    );
    if (error) {
      props.setData({
        error: error,
      });
      props.setRegStatus(3);
    } else {
      props.setData({
        username: username,
        email: email,
        password: password,
        error: null,
      });
      props.setRegStatus(1);
    }
  };

  // analyse username and show results
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (username === "") {
        setUsernameInterface(<UserIcon />);
        setUsernameHint("form.registration.username.description");
      } else if (!validUsername(username)) {
        setUsernameInterface(<NotValidIcon />);
        setUsernameHint("form.registration.username.hint");
      } else if (validUsername(username)) {
        setUsernameInterface(
          <UserReq
            username={username}
            usernameAvailable={usernameAvailable}
            setUsernameHint={setUsernameHint}
          />
        );
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [username]);

  // analyse email and show results
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (email === "") {
        setEmailInterface(<EmailIcon />);
        setEmailHint("form.registration.email.description");
      } else if (!validEmail(email)) {
        setEmailInterface(<NotValidIcon />);
        setEmailHint("form.registration.email.hint");
      } else if (validEmail(email)) {
        setEmailInterface(
          <EmailReq
            email={email}
            emailAvailable={emailAvailable}
            setEmailHint={setEmailHint}
          />
        );
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [email]);

  // analyse password and show results
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (password === "") {
        setPasswordInterface(<PassIcon />);
        setPasswordHint("form.registration.password.description");
      } else if (!validPassword(password)) {
        setPasswordInterface(<NotValidIcon />);
        setPasswordHint("form.registration.password.hint");
      } else if (validPassword(password)) {
        setPasswordInterface(<ValidIcon />);
        setPasswordHint("form.registration.password.valid");
      }
      changePassConfInterface();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [password]);

  // analyse password confirmation and show result as icon
  useEffect(() => {
    const timeout = setTimeout(() => {
      // extracted because of reuse in useEffect of changing password
      changePassConfInterface();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [passwordConfirmation]);

  // extracted change of password confirmation interface
  const changePassConfInterface = () => {
    if (passwordConfirmation === "") {
      setPasswordConInterface(<PassIcon />);
      setPasswordConHint("form.registration.passwordConfirmation.description");
    } else if (!validPasswordConfirmation(password, passwordConfirmation)) {
      setPasswordConInterface(<NotValidIcon />);
      setPasswordConHint("form.registration.passwordConfirmation.hint");
    } else if (validPasswordConfirmation(password, passwordConfirmation)) {
      setPasswordConInterface(<ValidIcon />);
      setPasswordConHint("form.registration.passwordConfirmation.valid");
    }
  };

  return (
    <RegistrationView
      handleSubmit={handleSubmit}
      usernameInterface={usernameInterface}
      setUsername={(e) => setUsername(e.target.value)}
      usernameHint={usernameHint}
      emailInterface={emailInterface}
      setEmail={(e) => setEmail(e.target.value)}
      emailHint={emailHint}
      passwordInterface={passwordInterface}
      setPassword={(e) => setPassword(e.target.value)}
      passwordHint={passwordHint}
      passwordConInterface={passwordConInterface}
      setPasswordConfirmation={(e) => setPasswordConfirmation(e.target.value)}
      passwordConHint={passwordConHint}
      reqButton={props.reqButton}
    />
  );
}

// submit button and possible info message
function ReqButton(props) {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  if (props.error) {
    return (
      <div className={m("btn-wrap")}>
        <div className={m("message-wrap")}>
          <div className={m("message-form")}>
            <div className={m("message-icon")}>
              <span className={m("material-icons")}>error</span>
            </div>
            <div className={m("message-text")}>{t(props.error)}</div>
          </div>
        </div>
        <div className={m("btn response")}>
          <button type="submit">
            <div className={m("btn-text")}>{t("form.registration.action")}</div>
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={m("btn-wrap")}>
        <div className={m("btn")}>
          <button type="submit">
            <div className={m("btn-text")}>{t("form.registration.action")}</div>
          </button>
        </div>
      </div>
    );
  }
}

// submit button and possible message during and after request
export function RegReq(props) {
  return (
    <Post
      url={url.concat("register")}
      data={{
        username: props.data.username,
        eMail: props.data.email,
        password: props.data.password,
      }}
    >
      {(error, response, isLoading, makeRequest, axios) => {
        if (error) {
          // if error
          return (
            // TODO: display error messages
            <RegForm reqButton={<RegFailed />} />
          );
        } else if (isLoading) {
          return <RegForm reqButton={<RegLoading />} />;
        } else if (response !== null) {
          // if registration successful
          if (response.data.bool) {
            // alert(t("form.registration.alert.success"));
            return <RegSuccess />;
          } else {
            return <RegForm reqButton={<RegFailed />} />;
          }
        }
        // default message before request is made
        return <RegForm reqButton={<RegLoading />} />;
      }}
    </Post>
  );
}

function RegistrationView(props) {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();
  const {
    handleSubmit,
    usernameInterface,
    setUsername,
    usernameHint,
    emailInterface,
    setEmail,
    emailHint,
    passwordInterface,
    setPassword,
    passwordHint,
    passwordConInterface,
    setPasswordConfirmation,
    passwordConHint,
    reqButton
  } = props;

  return (
    <div className={m("registration form-wrap")}>
      <form onSubmit={handleSubmit}>
        <div className={m("form")}>
          <div className={m("forms-title")}>{t("form.registration.title")}</div>
          <div className={m("single-signon")}>
            <div className={m("btn-google-wrap")}>
              <div className={m("btn-google")}>
                <img
                  className={m("btn-google-icon")}
                  alt="Google Registrierung"
                ></img>
                <div className={m("btn-google-text")}>
                  {t("form.registration.google")}
                </div>
              </div>
            </div>
          </div>
          <div className={m("mask")}>
            <div className={m("input-form-wrap")}>
              <div className={m("input-form")}>
                {usernameInterface}
                <input
                  id="user"
                  className={m("input")}
                  onChange={setUsername}
                  placeholder={t("form.registration.username.title")}
                ></input>
              </div>
              <InputHint text={usernameHint} />
            </div>
            <div className={m("input-form-wrap")}>
              <div className={m("input-form")}>
                {emailInterface}
                <input
                  id="email"
                  className={m("input")}
                  onChange={setEmail}
                  placeholder={t("form.registration.email.title")}
                ></input>
              </div>
              <InputHint text={emailHint} />
            </div>
            <div className={m("input-form-wrap")}>
              <div className={m("input-form")}>
                {passwordInterface}
                <input
                  id="password"
                  className={m("input")}
                  type="password"
                  autoComplete="new-password"
                  onChange={setPassword}
                  placeholder={t("form.registration.password.title")}
                ></input>
              </div>
              <InputHint text={passwordHint} />
            </div>
            <div className={m("input-form-wrap")}>
              <div className={m("input-form")}>
                {passwordConInterface}
                <input
                  id="password-confirmation"
                  className={m("input")}
                  type="password"
                  autoComplete="new-password"
                  onChange={setPasswordConfirmation}
                  placeholder={t(
                    "form.registration.passwordConfirmation.title"
                  )}
                ></input>
              </div>
              <InputHint
                style={{
                  marginTop: "-128px",
                }}
                text={passwordConHint}
              />
            </div>
            {reqButton}
          </div>
        </div>
      </form>
    </div>
  );
}