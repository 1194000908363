import "../../scss/views/profile.scss";
import ExMain from "../ExMain";
import { ProfileFriend } from "../components/profile/friends/ProfileFriend";
import { ProfileEvent } from "../components/profile/events/ProfileEvent";
import { profileData as data } from "../utils/ExampleData";
import { ModeContext } from "../utils/Mode";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import useToken from "../utils/Token";
import { Link } from "react-router-dom";

export default function Profile(props) {
  return <ExMain location="/profile" card2={<Page location="/profile" />} />;
}

const friends = Object.values(data.friends);
const events = Object.values(data.events);

function Page(props) {
  const { m } = useContext(ModeContext);
  const { t } = useTranslation();

  return (
    <div className={m("profile-wrap")}>
      <ProfileOptionPanel mobile={false} />
      <div className={m("profile")}>
        <div className={m("profile-user-card-wrap")}>
          <div className={m("profile-user-card")}>
            <ProfileOptionPanel mobile={true} />
            <div className={m("profile-picture-wrap")}>
              <div className={m("profile-picture-placeholder")}>
                <span className={m("material-icons")}>person</span>
              </div>
              <div className={m("profile-picture")}>
                {/* profile picture */}
              </div>
              <div className={m("profile-function-wrap")}>
                <ProfileFunction icon="add" function={() => {}} />
              </div>
            </div>

            <div className={m("profile-content-wrap")}>
              <div className={m("mobile-profile-dashboard-header")}>
                <div className={m("mobile-profile-dashboard-title")}>
                  {data.profile.name}
                </div>
                <div className={m("mobile-profile-dashboard-title-adds-wrap")}>
                  <div
                    className={m("mobile-profile-dashboard-title-adds-icon")}
                  >
                    <span className={m("material-icons")}>place</span>
                  </div>
                  <div
                    className={m("mobile-profile-dashboard-title-adds-text")}
                  >
                    {data.profile.city + ", " + data.profile.country}
                  </div>
                </div>
              </div>
              <div className={m("profile-description-wrap")}>
                <div className={m("profile-description-title")}>
                  {t("functions.profile.about.title")}
                </div>
                <div className={m("profile-description-text")}>
                  {data.profile.description}
                </div>
              </div>
              <div className={m("profile-links-wrap")}>
                <div className={m("profile-links-title")}>
                  {t("functions.profile.links")}
                </div>
                <div className={m("profile-links-table")}>
                  {/* profile links */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={m("profile-dashboard-wrap")}>
          <div className={m("profile-dashboard")}>
            <div className={m("profile-dashboard-header")}>
              <div className={m("profile-dashboard-title")}>
                {data.profile.name}
              </div>
              <div className={m("profile-dashboard-title-adds-wrap")}>
                <div className={m("profile-dashboard-title-adds-icon")}>
                  <span className={m("material-icons")}>place</span>
                </div>
                <div className={m("profile-dashboard-title-adds-text")}>
                  {data.profile.city + ", " + data.profile.country}
                </div>
              </div>
            </div>

            <div className={m("profile-dashboard-body-wrap")}>
              <div className={m("profile-dashboard-body")}>
                <div className={m("profile-dashboard-events")}>
                  {events.map((object, index) => (
                    <ProfileEvent key={index} data={events[index]} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={m("profile-friendlist-placeholder")}></div>
        <div className={m("profile-friendlist-wrap")}>
          <div className={m("profile-friendlist")}>
            {friends.map((object, index) => (
              <ProfileFriend key={index} data={friends[index]} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileOptionPanel(props) {
  const { m } = useContext(ModeContext);

  return (
    <div className={props.mobile ? m("profile-options-wrap mobile") : m("profile-options-wrap no-mobile")}>
      <div className={m("profile-options")}>
        <Link
          to={{
            pathname: "/profile/settings",
            state: {
              from: props.location,
            },
          }}
        >
          <ProfileOption icon="edit" needPerm={false} />
        </Link>
      </div>
    </div>
  );
}

function ProfileOption(props) {
  const { m } = useContext(ModeContext);
  if (props.needPerm ? useToken.validateToken : true) {
    return (
      <div className={m("profile-option")}>
        <div className={m("profile-option-icon")}>
          <span className={m("material-icons")}>{props.icon}</span>
        </div>
      </div>
    );
  } else return null;
}

function ProfileFunction(props) {
  const { m } = useContext(ModeContext);
  return (
    <div className={m("profile-function")}>
      <div className={m("profile-function-icon")} onClick={props.function()}>
        <span className={m("material-icons")}>{props.icon}</span>
      </div>
    </div>
  );
}
