import { url } from "./Config";
import axios from "axios";
import { useState } from "react";

export default function useToken(props) {
  const getToken = () => {
    const tokenString = localStorage.getItem("session");
    const userToken = JSON.parse(tokenString);
    if (userToken !== undefined) {
      return userToken;
    }
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    if (userToken) {
      // save token to local storage
      localStorage.setItem("session", JSON.stringify(userToken));
      setToken(userToken);
    }
    // trigger re-rendering
  };

  // logout
  const removeToken = () => {
    axios.post(url.concat("logout"), {
      session: token,
    });
    localStorage.removeItem("session");
    setToken(null);
    return true;
  };

  const validateToken = async () => {
    try {
      const response = await axios.post(url.concat("session/validation"), {
        session: getToken(),
      });
      if (response.data) {
        return true;
      } else {
        localStorage.removeItem("session");
        setToken(null);
        return false;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return {
    setToken: saveToken,
    removeToken: removeToken,
    validateToken: validateToken,
    token,
  };
}