const initialState = Object.freeze("");

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_SESSION": {
      return action.payload;
    }
    case "DELETE_SESSION": {
      return "";
    }
    default: {
      return state;
    }
  }
}