import "../../../scss/components/slideshow/slideshow.scss";
import sliders from "./Sliders";
import { useContext, useState } from "react";
import { ModeContext } from "../../utils/Mode";

export default function Slideshow(props) {
  const [slides, setSlides] = useState(sliders()); // Array containing sliders
  const [slide, setSlide] = useState(slides[0]);

  /**
   * arrow left / slide left navigation
   */
  const slideLeft = () => {
    const i = slides.findIndex((i) => i === slide);
    setSlide(slides[((i - 1 < 0 ? i + slides.length : i) - 1) % slides.length]);
  };

  /**
   * arrow right / slide right navigation
   */
  const slideRight = () => {
    setSlide(
      slides[(slides.findIndex((i) => i === slide) + 1) % slides.length]
    );
  };

  const SliderEntries = () => {
    return slides.map((object, index) => (
      <SliderEntry
        key={index}
        index={index}
        title={index + 1}
        setSlide={() => setSlide(slides[index])}
      />
    ));
  };

  return (
    <SlideshowView
      slide={slide}
      slides={slides}
      slideLeft={() => slideLeft()}
      slideRight={() => slideRight()}
      setSlide={(e) => setSlide(e)}
      SliderEntries={SliderEntries}
    />
  );
}

function SlideshowView(props) {
  const { m } = useContext(ModeContext);
  const { slide, slideLeft, slideRight, SliderEntries } =
    props;

  return (
    <div className={m("slider")}>
      <div className={m("slider-nav-group")}>
        <div className={m("slider-nav slider-nav-left")} onClick={slideLeft}>
          <span className={m("material-icons")}>arrow_back_ios</span>{" "}
        </div>
        <div className={m("slider-nav slider-nav-right")} onClick={slideRight}>
          <span className={m("material-icons")}>arrow_forward_ios</span>
        </div>
      </div>
      <div className={m("slider-menu")}>
        <SliderEntries/>
      </div>
      <div className={m("slider-content")}>{slide}</div>
    </div>
  );
}

/**
 * labeled slide navigation
 */
function SliderEntry(props) {
  const { m } = useContext(ModeContext);
  const { title, setSlide } = props;

  return (
    <div className={m("slider-entry")} onClick={setSlide}>
      <div className={m("slider-rectangle")}>
        <div className={m("slider-entry-text")}>{title}</div>
      </div>
    </div>
  );
}
