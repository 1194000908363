import { getErrorMessage } from "../../utils/login/utils";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ModeContext } from "../../utils/Mode";

// TODO: display error messages
export function ErrorMessage(props) {
  const message = () => {
    return getErrorMessage(props.code);
  }

  return (
    <ErrorMessageView
      message={message}
    />
  );
}

function ErrorMessageView(props){
  const { t } = useTranslation();
  const { m } = useContext(ModeContext);
  const { message } = props;

  return(
    <div className={m("btn-wrap")}>
      <div className={m("message-wrap")}>
        <div className={m("message-form")}>
          <div className={m("message-icon")}>
            <span className={m("material-icons")}>error</span>
          </div>
          <div className={m("message-text")}>{t(message)}</div>
        </div>
      </div>
      <div className={m("btn response")}>
        <button type="submit">
          <div className={m("btn-text")}>{t("form.general.retry")}</div>
        </button>
      </div>
    </div>
  );
}