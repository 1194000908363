import "../../scss/components/functionpanel.scss";
import { Link } from "react-router-dom";
import { ModeContext } from "../utils/Mode";
import { useContext } from "react";

// Content of the first Card on Headpage
export default function FunctionPanel(props) {
  const { m } = useContext(ModeContext);
  return (
    <div className={m("function-panel")}>
      <Function
        location={props.location}
        path="/dashboard"
        title="3"
        description="Dashboard"
        icon="dashboard"
      />
      <Function
        location={props.location}
        path=""
        title="1"
        description="Hinzufügen"
        icon="add"
      />
      <Function
        location={props.location}
        path="/calendar"
        title="2"
        description="Kalender"
        icon="event_note"
      />
      <Function
        location={props.location}
        path=""
        title="4"
        description="Gruppen"
        icon="groups"
      />
      <Function
        location={props.location}
        path="/profile"
        title="5"
        description="Profil"
        icon="person"
      />
      <Function
        location={props.location}
        path="/settings"
        title="6"
        description="Einstellungen"
        icon="settings"
      />
    </div>
  );
}

function Function(props) {
  const { m } = useContext(ModeContext);
  const { path, location, icon } = props;

  return (
    <div className={m("exfunction-row")}>
      <Link
        to={{
          pathname: path,
          state: {
            from: location,
          },
        }}
      >
        <div className={m("function")}>
          <span className={m("material-icons")}>{icon}</span>
        </div>
      </Link>
    </div>
  );
}
