import { useContext } from "react";
import { ModeContext } from "./Mode";

// API URL
export const url = "https://meetto-api.com/";
// export const url = "https://api.meetto.de/";
// export const url = "http://localhost:8080/";
// export const url = "http://192.168.178.68:3000/";

export const colors = {
  primCol: "#F69E7B", // primary color
  secCol: "#383E56", // secondary color
};

// Headpage Pages - used for animations in Dashboard
export const hpp = ["/", "/login", "/registration", "/events"];

// Dashboard Pages - used for animations in Headpage
export const dbp = ["/dashboard", "/calendar", "/profile", "/settings"];

export function Init() {
  useContext(ModeContext).setFlag(true);

  return null;
}
