import "../../scss/views/landing.scss";
import Slideshow from "../components/slideshow/Slideshow";
import Main from "../Main";
import { Fragment, useContext } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ModeContext } from "../utils/Mode";

export default function Landing(props) {
  const { t } = useTranslation();
  
  return (
    <Fragment>
      <Helmet>
        <title>{t("metadata.landing.title")}</title>
        <meta name="description" content={t("metadata.landing.description")}/>
      </Helmet>
      <Main location="/" card2={<Page />} />
    </Fragment>
  );
}

function Page() {
  const { m } = useContext(ModeContext);
  return (
    <div className={m("landing")}>
      <Slideshow/>
    </div>
  );
}
