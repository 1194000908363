import "../scss/App.scss";
import "../scss/utils/colors.scss";
import Landing from "./views/Landing";
import Login from "./views/Login";
import Registration from "./views/Registration";
import Events from "./views/Events";
import DashboardLanding from "./views/DashboardLanding";
import Calendar from "./views/Calendar";
import ProfileSettings from "./views/ProfileSettings";
import Profile from "./views/Profile";
import Settings from "./views/Settings";
import Fallback from "./components/Fallback";
import ProtectedRoute from "./utils/ProtectedRoute";
import store from "./store/store";
import { Init } from "./utils/Config";
import { ModeContext, ModeProvider } from "./utils/Mode";
import useToken from "./utils/Token";
import { Suspense, useContext } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import i18next from "i18next";

function App() {
  Init();
  const { getThemeColor } = useContext(ModeContext);
  // userToken saved in localStorage to save session
  const { token, setToken, validateToken } = useToken();
  if (token) validateToken();

  return (
    <div className="App">
      <Helmet>
        <html lang={i18next.language}/>
        <meta name="theme-color" content={getThemeColor()}/>
      </Helmet>
      <Router>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/login">
            <Login setToken={setToken} />
          </Route>
          <Route path="/registration" component={Registration} />
          <Route path="/events" component={Events} />
          {/* ProtectedRoute can only acessed on auth true - if not: redirect*/}
          <ProtectedRoute
            path="/dashboard"
            auth={token}
            component={DashboardLanding}
            redirect="/login"
          />
          <ProtectedRoute
            path="/calendar"
            auth={token}
            component={Calendar}
            redirect="/login"
          />
          <ProtectedRoute
            exact
            path="/profile/settings"
            auth={token}
            component={ProfileSettings}
            redirect="/login"
          />
          <ProtectedRoute
            path="/profile"
            auth={token}
            component={Profile}
            redirect="/login"
          />
          <ProtectedRoute
            path="/settings"
            auth={token}
            component={Settings}
            redirect="/login"
          />
          <Route path="/">
            <Redirect to={{ pathname: "/" }} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

// catch the suspense from page in case translations are not loaded yet
export default function WrappedApp() {
  return (
    <Provider store={store}>
      <ModeProvider>
        <Suspense fallback={<Fallback />}>
          <App />
        </Suspense>
      </ModeProvider>
    </Provider>
  );
}
